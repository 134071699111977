footer {
    max-width: 100%;
    height: 150px;
    background-color: #030800;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: fixed;
    bottom: 0;
}
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
}
.footer-left {
    display: flex;
    flex-direction: column;
}
.get-in-touch {
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.get-in-touch img {
    margin-right: 10px;
}
.footer-right {
    display: flex;
    flex-direction: column;
}
@media(max-width: 500px) {
    footer {
        height: 180px;
    }
    .footer-container {
        flex-direction: column;
    }
    .footer-left {
        margin-top: 10px;
    }
    .footer-right {
        margin-bottom: 20px;
    }
    .insta-mail {
        display: flex;
    }
    .insta-mail a {
        margin: 10px 0;
    }
    .insta-mail .insta {
        margin-right: 10px;
    }
}
.download-app {
    display: flex;
    margin-top: 10px;
}
.download-app img {
    margin-right: 10px;
    height: 40px;
}