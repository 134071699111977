main {
    background-color: black;
}
.main-container {
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
}
.girl {
    height: 110%;
    align-self: center;
    position: fixed;
    bottom: 0;
}
.ball1 {
    height: 90%;
    align-self: center;
    position: fixed;
    bottom: 0;
}
.ball2 {
    height: 90%;
    align-self: center;
    position: fixed;
    bottom: 0;
}
.main-container .main-text {
    width: 351px;
    max-width: 100%;
    position: absolute;
    top: 10%;
    left: 20%;
}
.main-container .main-text h1 {
    margin-bottom: 10px;
}
.main-container .main-text p {
    max-width: 290px;
    line-height: 24px;
}
.main-container .text-description {
    position: absolute;
    right: 10%;
    bottom: 20%;
}
.text-description h2 {
    text-align: center;
    width: 320px;
    font-size: 22px;
    font-weight: 100;
}
/* Coordination section */
.coordination-section {
    display: flex;
    justify-content: center;
    overflow: hidden;

    height: 100vh;
    position: relative;
}
.coordination-section .main-text {
    position: absolute;
    right: 10%;
    top: 50%;
}
.coordination-section .main-text h1 {
    max-width: 250px;
    margin-bottom: 10px;
}
.coordination-section .main-text p {
    max-width: 290px;
    line-height: 24px;
}
/* Perfect match section */

.perfect-match-section {
    background-size: cover;
    display: flex;
    justify-content: center;
    overflow: hidden;

    height: 100vh;
    position: relative;
}
.group-images {
    display: flex;
    justify-content: center;
    align-items: center;
}

.perfect-match-section .text-description {
    position: absolute;
    right: 10%;
    bottom: 10%;
    width: 300px;
}
.perfect-match-section .main-text {
    width: 400px;
    max-width: 90%;
    position: absolute;
    top: 10%;
    left: 20%;
}
@media (max-width: 700px) {
    .main-container .main-text {
        margin-top: 60px;
    }
    .girl {
        height: 600px;
        left: 20%;
    }
    .ball1 {
        height: 500px;
        left: 20%;
    }
    .ball2 {
        height: 600px;
        left: 0%;
    }
    h1 {
        font-size: 18px;
        width: 100%;
    }
    .main-container .main-text {
        left: 5%;
        width: 100%;
    }
    .main-container .text-description h2 {
        text-align: start;
        font-size: 12px;
        line-height: 24px;
        width: 70%;
    }
    .main-container .text-description {
        left: 5%;
    }
    .coordination-section .main-text {
        left: 5%;
        width: 240px;
    }
    .perfect-match-section h1 {
        width: 200px;
    }
    .perfect-match-section .main-text {
        left: 5%;
        width: 280px;
    }
    .perfect-match-section .text-description {
        right: 5%;
        width: 85%;
    }
}
.perfect-match-section .main-text h1 {
    margin-bottom: 10px;
}
p {
    line-height: 28px;
}

/* Services Section */
.services-section {
    min-height: 100vh;

    display: flex;
    align-items: center;
    flex-direction: column;

    position: relative;
}
.services-section p {
    display: flex;
    max-width: 70%;
    align-items: center;
    justify-content: center;
    width: 787px;
    margin-left: 15vw;
    margin-top: 100px;
    margin-bottom: 30px;
    line-height: 20px;
}
@media (max-width: 700px) {
    .services-section p {
        font-size: 11px;
        line-height: 15px;
    }
}
.services {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    max-width: 1100px;
    flex-wrap: wrap;
    margin-left: 15vw;
}
.service {
    width: 240px;
    height: 330px;
    margin: 10px;
    border-radius: 10px;

    display: flex;
    align-items: flex-end;
}
@media (max-width: 1400px) {
    .services-section p {
        width: 550px;
    }
    .services-section {
        min-height: 140vh;
    }
    .services {
        max-width: 700px;
        top: 20%;
    }
}
@media (max-width: 900px) {
    .services-section p {
        margin-left: 0;
        max-width: 90%;
    }
    .services {
        margin-left: 0;
    }
    .services-section {
        min-height: 140vh;
    }
}
.service button {
    margin: 20px;
    width: 165px;
    height: 41px;

    background-color: #73BB3E;
    color: white;
    border: none;
    border-radius: 1vw;
    cursor: pointer;
}
@media (max-width: 600px) {
    .service {
        width: 42vw;
        height: 50vw;
    }
    .service button {
        margin: 5px;
        height: 35px;
    }
}
.photo {
    background-size: cover;
    background-position: center;
}
.photo_0x01 {
    background-image: url(../../public/images/photo_0x01.png);
}
.photo_0x02 {
    background-image: url(../../public/images/photo_0x02.png);
}
.photo_0x03 {
    background-image: url(../../public/images/photo_0x03.png);
}
.photo_0x04 {
    background-image: url(../../public/images/photo_0x04.png);
}