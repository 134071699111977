@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideInDown {
    from {
        opacity: 0;
        transform: translateY(-200px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-200px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(200px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes scale {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes opacity {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate {
    opacity: 0; /* Start with opacity 0 */
    transform: translateY(0);
}

.animate.slideInUp {
    animation: slideInUp 1s ease forwards; /* Apply animation */
}
.animate.slideInDown {
    animation: slideInDown 1s ease forwards; /* Apply animation */
}
.animate.slideInLeft {
    animation: slideInLeft 1s ease forwards; /* Apply animation */
}
.animate.slideInRight {
    animation: slideInRight 1s ease forwards; /* Apply animation */
}
.animate.scale {
    animation: scale 1s ease forwards; /* Apply animation */
}
.animate.opacity {
    animation: opacity 1s ease forwards; /* Apply animation */
}