.contact-section {
    height: 100vh;
    position: relative;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 100px;
}
form {
    display: flex;
    flex-direction: column;

    width: 280px;
}
form input {
    background-color: rgb(0, 0, 0, 0);
    color: white;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid white;
    margin-top: 20px;
    height: 40px;
}
.company-name::placeholder {
    color: rgb(158, 158, 158);
}
form input::placeholder {
    color: white;
}
form input:focus {
    border-bottom: 3px solid white;
    outline: none;
}

form select {
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    margin-top: 20px;
    height: 40px;
    position: relative;
    border-radius: none;
}
form select option {
    background-color: #102500;
}

form button {
    height: 40px;
    margin-top: 15px;
    border: none;
    border-radius: 5px;
    background-color: #73BB3E;
    color: white;
    cursor: pointer;
}
form button:active {
    background-color: white;
    color: #73BB3E;
}
.hand1 {
    position: fixed;
    max-width: 90%;
    right: 0;
    top: 0;
    image-rendering: -webkit-optimize-contrast; /* For Webkit-based browsers (like Safari) */
}
.hand2 {
    position: fixed;
    max-width: 90%;
    bottom: 14%;
    left: 0;
    rotate: 180deg;
    image-rendering: -webkit-optimize-contrast; /* For Webkit-based browsers (like Safari) */
}
@media (max-width: 600px) {
    .hand1 {
        top: 10%;
    }
    .hand2 {
        top: 45%;
    }
    form input {
        height: 20px;
    }
}