.welcome-main {
    background-color: black;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    animation: animateOpacity 0.2s ease-in-out 1.8s forwards;
}
.nexus-container {
    display: flex;
    align-items: center;
    animation: animateNEXUS 2s ease-in-out 1s forwards;
}
.nexus-container h3 {
    color: white;
    font-size: 110px;
    margin-bottom: 15px;
}
@media (max-width: 700px) {
    .nexus-container h3 {
        margin-bottom: 0;
    }
}
.x {
    height: 90px;
    animation: animateX 1s ease-in-out forwards;
}
.ne {
    margin-right: 10px;
    animation: animateNE 1s ease-in-out forwards;
    transform: translateX(-300%);
}
.us {
    animation: animateUS 1s ease-in-out forwards;
    transform: translateX(300%);
}
@keyframes animateX {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }

}
@keyframes animateNE {
    0% {
        transform: translateX(-300%);
    }
    100% {
        transform: translateX(0);
    }

}
@keyframes animateUS {
    0% {
        transform: translateX(300%);
    }
    100% {
        transform: translateX(0);
    }

}
@keyframes animateNEXUS {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(0.2);
    }
    100% {
        transform: scale(60);
    }

}
@keyframes animateOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        z-index: 0;
        display: none;
    }
}