h1 {
  font-size: 36px;
}
h2 {
  font-size: 32px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 21px;
}
h5 {
  font-size: 20px;
}
p {
  font-size: 13px;
}


.c_lg {
  color: #E2F2D8;
}
.c_g {
  color: #73BB3E;
}
.c_blu {
  color: #22A5A5;
}
a {
  text-decoration: none;
  font-size: 14px;
}