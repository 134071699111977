.main-background-green {
    position: fixed;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        to right, 
        black 30%, 
        rgb(0, 37, 0) 50%, 
        black 80%
    );
    background-size: 200% 100%; /* Adjust size to enable smooth animation */
    animation: moveGradient 20s linear infinite;
    display: flex;
    flex-direction: column;
}
.main-background-blue {
    position: fixed;
    height: 100%;
    width: 100%;
    background: linear-gradient(
        to right, 
        black 30%, 
        rgb(0, 20, 37) 50%, 
        black 80%
    );
    background-size: 200% 100%;
    animation: moveGradient 20s linear infinite;
}
@keyframes moveGradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 0%;
    }
    100% {
        background-position: 0% 0%;
    }
}