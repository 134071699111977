.header-big {
    position: fixed;
    height: 379px;
    margin-left: 50px;
    margin-top: 80px;
    width: 160px;

    display: flex;
    flex-direction: column;
}
.logo {
    height: 81.91px;
    width: 16.2px;
    align-self: center;
    margin-bottom: 100px;
    cursor: pointer;
}
.header-nav {
    display: flex;
    flex-direction: column;
}
.nav-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 30px;
}
.nav-item img {
    margin-right: 5px;
    height: 20px;
    width: 20px;
}
.nav-item a {
    display: flex;
    align-items: center;
}
.nav-item h3 {
    font-size: 14px;
}
.hi {
    width: 48.28px;
    align-self: center;
}
.big-nav {
    height: 70px;
    display: flex;
    align-items: center;
}
.big-nav img {
    margin-right: 5px;
}
.small-nav {
    height: 30px;
    display: flex;
    align-items: center;
}
.contact-us-nav {
    display: flex;
    align-items: center;
    height: 60px;
}
.contact-us-nav img {
    margin-right: 5px;
}




/* Header small */

.header-small {
    width: 100%;
    height: 90px;
    display: flex;
    position: fixed;
    top: 0;
    background-color: rgb(0, 0, 0, .9);
}
.header-nav-small {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-small {
    position: relative;
    top: 20px;
    left: 20px;
}
.logo-small img {
    height: 50px;
}
.nav-item-small {
    display: flex;
    align-items: center;
    height: 100%;

    position: relative;
}
.nav-item-small img {
    position: absolute;
    right: 40px;
    width: 20px;
    height: 20px;
}
.nav-item-small h4 {
    position: relative;
    font-size: 13px;
    margin: 0 20px;
}